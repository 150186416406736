<template>
  <div class="lscardindex">
    <div class="lvsbox flexbox">
          <div class="lvsitem flexbox" v-for="(item,i) in list" :key="i" @click.stop="tourl('/lvdetails?id=' + item.id)">
            <img :src="item.photo" alt="" class="lsimg" />
            <div class="lsright">
              <div class="text1"> <span>{{item.lawyerName}}律师 </span>  <span class="zhispan">执业{{item.workYears}}年</span></div>
              <p class="text2">
                好评率：<span>{{item.haopinhlv}}%</span>接单率: <span>{{item.jiedanums}}次</span>
              </p>
              <p class="text3 flexbox">
                <span v-for="(data,index) in item.goodsIdsArr" :key="index">{{data}}</span>
              </p>
              <p class="text4">{{item.addr}}</p>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    list:{
      type:Array,
      default:(()=>[])
    }
  
  },
  data() {
    return {
     
    }
  },

  mounted() {
   
  },

  methods: {
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
  }
}
</script>

<style lang="scss">
.lvsbox {
        flex-wrap: wrap;
        .lvsitem {
          width: 403px;
          // height: 163px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid #e6e6e6;
          margin-right: 24px;
          margin-bottom: 24px;
          padding: 16px;
          .lsimg {
            width: 124px;
            height: 164px;
            border-radius: 10px;
          }
          .lsright {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            min-height: 164px;
            margin-left: 16px;
            .text1 {
             font-size: 20px;
              font-weight: 500;
              color: #333333;
              .zhispan{
               font-size: 16px;
                color: #333333;
                margin-left: 13px;
              }
            }
            .text2 {
             font-size: 16px;
              color: #333333;
              span {
                color: #305bfe;
                margin-right: 13px;
              }
            }
            .text3 {
              flex-wrap: wrap;
              span {
                padding: 3px 6px;
                border-radius: 12px;
                border: 1px solid #cccccc;
                font-size: 13px;
                color: #333333;
                margin-right: 7px;
                margin-bottom: 4px;
              }
              span:nth-child(3n) {
                margin-right: 0px;
              }
            }
            .text4 {
             font-size: 16px;
              color: #666666;
            }
          }
        }
        .lvsitem:hover{
          cursor: pointer;
          border: 1px solid #305bfe;
        }
        .lvsitem:nth-child(2n) {
          margin-right: 0px;
        }
        .more {
          // margin-top: 33px;
         font-size: 16px;
          color: #777777;
          margin-right: 10px;
          cursor: pointer;
          img {
            width: 6px;
            height: 11px;
          }
        }
      }
        // 分页
   .fenyebox{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

</style>