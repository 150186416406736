<template>
  <div class="newstu">
    <div class="artitem flexbox" v-for="(item,i) in list" :key="i" @click.stop="tourl('/infodetails?id=' + item.id)">
      <img :src="item.photo" alt="" class="artimg" v-if="item.photo"/>
      <img src="@/assets/img/404.png" alt="" class="artimg" v-else>
      <div class="artright">
        <div>
          <p class="title1 line1">{{item.title}}</p>
          <div class="text1 line3">{{item.content | filtersText}}</div>
        </div>

        <div class="flex_between bottomttt">
          <p class="time">{{item.createTime}}</p>
          <div v-if="zan" class="flexbox"> 
            <p @click.stop="toggleStatus(1,i,item.id)">
              <img src="@/assets/img/zan1.png" alt="" v-if="item.islike == 1">  
              <img src="@/assets/img/zan.png" alt="" v-if="item.islike == 0">
            </p>
            <p @click.stop="toggleStatus(2,i,item.id)">
              <img src="@/assets/img/xing1.png" alt="" v-if="item.iscollect == 1">  
              <img src="@/assets/img/xing.png" alt="" v-if="item.iscollect == 0">
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="morewen flexbox" v-if="more" @click.stop="tourl('/infolist')">
      更多
      <img src="@/assets/img/right.png" alt="" />
    </div>

       <!-- 分页 -->
       <!-- <div  v-if="paging" class="fenyebox">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1000">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: '',
  components: {},
  props: {
    /***
     * 是否显示  更多
     */ 
    more: {
      type: Boolean,
      default: false
    },
    /***
     * 是否显示分页
     */ 
     paging: {
      type: Boolean,
      default: false
    },
    /***
     * 是否显示点赞
     */ 
     zan: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: (()=>[])
    },
  },
  filters:{
    // 富文本转文本
    filtersText(val){
        if (val != null && val != '') {
            let reg = /[\u4e00-\u9fa5]/g;
            let names = val.match(reg);
            val = names ? names.join('') : '';
            return val;
        } else return '';
    },
},
  data() {
    return {
     
    }
  },

  mounted() {},

  methods: {
      // 跳转
      tourl(url) {
        this.$router.push({
          path: url
        })
      },
      toggleStatus(type, index, id) {
				let url = '';
				if (!localStorage.getItem('userId')) return;
				if (type == 1) {
					//点赞
					url = apiUrl.likeinformation;
				} else {
					// 收藏
					url = apiUrl.collectinformation;
				}
        let data = {
          id: id,
					userId: localStorage.getItem('userId')
        }
        url(data).then(res => {
          this.$message({
            message:res.msg,
            type:'none'
          })
					if (res.code == 0) {
						if (type == 1) {
							this.list[index].islike = this.list[index].islike == 0 ? 1 : 0;
						} else {
							this.list[index].iscollect = this.list[index].iscollect == 0 ?1 : 0;
						}
					}
				})
			},
  }
}
</script>

<style lang="scss">
.newstu {
  .artitem {
    margin-top: 20px;
    cursor: pointer;
  }
  .artimg {
    width: 304px;
    height: 172px;
    border-radius: 12px;
    margin-right: 24px;
  }
  .artright {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 172px;
    .title1 {
      font-size: 20px;
      font-weight: 500;
      color: #333333;

      width: 502px;
    }
    .text1 {
      font-size: 16px;
      color: #333333;
      line-height: 25px;
      margin-top: 16px;
      text-indent: 64px;
    }
    .time {
      font-size: 14px;
      color: #777777;
    }
  }
  .bottomttt{
    img{
      width: 17px;
      height: 17px;
      margin-left: 20px;
    }
  }
  // 更多问题
  .morewen{
    font-size: 16px;
    color: #777777;
    margin-top: 30px;
    cursor: pointer;
    img{
      width: 22px;
      height: 22px;
      margin-left: 4px;
    }
  }
   // 分页
   .fenyebox{
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>