<template>
    <div class="followindex">
      
      <div class="tabsbox flexbox"> 
        <p @click.stop="change(1)" :class="[tabItem == 1 ? 'active' : '']">我的收藏</p>
        <p @click.stop="change(2)" :class="[tabItem == 2 ? 'active' : '']">我的关注</p>
      </div>
      <div class="main">
          <newstu :zan="true" v-if="tabItem == 1" :list="list" ></newstu>
          <lscard v-if="tabItem == 2" :list="list"></lscard>

          <NoData v-if="list.length <= 0"></NoData>
       </div>

       <!-- <div class="paginationblock">
        <el-pagination
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.currentPage"
          :page-size="pagination.size"
          layout="total, prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div> -->
    </div>
</template>

<script>

import  newstu from  '@/components/newstu'
import  lscard from  '@/components/lscard'
import NoData  from '@/components/NoData'

import apiUrl from '@/api/main'
export default {
    components: {newstu,lscard,NoData},
    data() {
        return {
          tabItem:1, // 1 我的收藏 2 我的关注
          pagination:{
            size:5, // 每页几条
            currentPage: 1,
            total: 0
          },
          list:[],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.pagination.currentPage = 1;
      this.getall(); // 收藏的文章
    },
    methods: {
      change(num){
        this.tabItem = num;
        this.pagination.currentPage = 1;
        if(num == 1){
          this.getall();
        }else {
          this.getlvlist();
        }
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        if(this.tabItem ==1){
          this.getall();
        }else {
          this.getlvlist();
        }
      },
       // 文章
      getall(){
        let data = {
          // pageSize: this.pagination.size,
          // pageNum: this.pagination.currentPage,
          userId:localStorage.getItem('userId')
        }
        apiUrl.lsUsercollect(data).then((res) => {
          this.list = res.rows;
          // this.pagination.total = res.total;
        })

      },
      // 律师
      getlvlist(){
        let data = {
          // pageSize: this.pagination.size,
          // pageNum: this.pagination.currentPage,
          userId:localStorage.getItem('userId')
        }
        apiUrl.lsUserLikeLawyer(data).then((res) => {

          res.rows.forEach((element) => {
            if (element.goodsIds) {
              element.goodsIdsArr = element.goodsIds.split(',')
            }
          })
          this.list = res.rows;
          // this.pagination.total = res.total;
        })

      },
    },
   
    
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.followindex{
  padding: 33px;
  background-color: #fff;


  .tabsbox{
      height: 40px;
      line-height: 41px;
      border-bottom: 1px solid  #ECECEC;
      p{
        font-size: 18px;
        color: #333333;
        margin-right: 64px;
        cursor: pointer;
      }
      .active{
          font-weight: 600;
          color: #305BFE;
          border-bottom: 3px solid #305BFE;
      }
    }

    .main{
      margin-top: 21px;
    }
}
</style>